import { default as adminsqtcos578xzMeta } from "/tmp/build_2e304229/pages/administration/admins.vue?macro=true";
import { default as _91id_93QzIziIR7mjMeta } from "/tmp/build_2e304229/pages/administration/billing/[id].vue?macro=true";
import { default as indexEGXdpPaziHMeta } from "/tmp/build_2e304229/pages/administration/billing/index.vue?macro=true";
import { default as bookingsQKhQcZjPDKMeta } from "/tmp/build_2e304229/pages/administration/bookings.vue?macro=true";
import { default as ecostatisticsyibNNGN2l7Meta } from "/tmp/build_2e304229/pages/administration/ecostatistics.vue?macro=true";
import { default as exports5J8A1QyiTKMeta } from "/tmp/build_2e304229/pages/administration/exports.vue?macro=true";
import { default as fidelityloNOma22oNMeta } from "/tmp/build_2e304229/pages/administration/fidelity.vue?macro=true";
import { default as invoicesS2VIFNiBFHMeta } from "/tmp/build_2e304229/pages/administration/invoices.vue?macro=true";
import { default as policy9XKFWLZJuUMeta } from "/tmp/build_2e304229/pages/administration/organization/policy.vue?macro=true";
import { default as _91id_93DyqlPiA1TBMeta } from "/tmp/build_2e304229/pages/administration/payment/[id].vue?macro=true";
import { default as index5yfPcHElc4Meta } from "/tmp/build_2e304229/pages/administration/payment/index.vue?macro=true";
import { default as statisticsJJke0RvVErMeta } from "/tmp/build_2e304229/pages/administration/statistics.vue?macro=true";
import { default as indexPYLNlgQxBFMeta } from "/tmp/build_2e304229/pages/administration/teams/[teamId]/index.vue?macro=true";
import { default as invitationYmjvVE7vrcMeta } from "/tmp/build_2e304229/pages/administration/teams/[teamId]/invitation.vue?macro=true";
import { default as indexISTOZWHfYWMeta } from "/tmp/build_2e304229/pages/administration/teams/[teamId]/policy/index.vue?macro=true";
import { default as modifygOLOC4gtXuMeta } from "/tmp/build_2e304229/pages/administration/teams/[teamId]/policy/modify.vue?macro=true";
import { default as validatorsSQphSZk15gMeta } from "/tmp/build_2e304229/pages/administration/teams/[teamId]/validators.vue?macro=true";
import { default as _91teamId_93IBXjIGTKTOMeta } from "/tmp/build_2e304229/pages/administration/teams/[teamId].vue?macro=true";
import { default as indexu0iyexPz4LMeta } from "/tmp/build_2e304229/pages/administration/teams/index.vue?macro=true";
import { default as trip_45labelsuCyzeuzhVMMeta } from "/tmp/build_2e304229/pages/administration/trip-labels.vue?macro=true";
import { default as administrationqT1ysyV6TCMeta } from "/tmp/build_2e304229/pages/administration.vue?macro=true";
import { default as swileZTQlaElAoGMeta } from "/tmp/build_2e304229/pages/auth/swile.vue?macro=true";
import { default as indexSRx1AKpZoWMeta } from "/tmp/build_2e304229/pages/bookings/index.vue?macro=true";
import { default as indexgWKSEmuCKDMeta } from "/tmp/build_2e304229/pages/bookings/validator/index.vue?macro=true";
import { default as pendingogTAoNKNhoMeta } from "/tmp/build_2e304229/pages/bookings/validator/pending.vue?macro=true";
import { default as _91id_930vv80TNugeMeta } from "/tmp/build_2e304229/pages/car/[id].vue?macro=true";
import { default as fidelitylHrQTPgOTCMeta } from "/tmp/build_2e304229/pages/fidelity.vue?macro=true";
import { default as exchangeCv65Vi9rBoMeta } from "/tmp/build_2e304229/pages/flight/[id]/exchange.vue?macro=true";
import { default as faresbT12WeqdEKMeta } from "/tmp/build_2e304229/pages/flight/[id]/fares.vue?macro=true";
import { default as indexEKWcgFSW5eMeta } from "/tmp/build_2e304229/pages/flight/[id]/index.vue?macro=true";
import { default as forgotHekMBPrEyEMeta } from "/tmp/build_2e304229/pages/forgot.vue?macro=true";
import { default as get_45swile_45appGJcc85pAK0Meta } from "/tmp/build_2e304229/pages/get-swile-app.vue?macro=true";
import { default as helpqXzGPpVP5DMeta } from "/tmp/build_2e304229/pages/help.vue?macro=true";
import { default as _91id_93WuQgR7Wd5hMeta } from "/tmp/build_2e304229/pages/hotel/[id].vue?macro=true";
import { default as _91id_93nysJbxyHBGMeta } from "/tmp/build_2e304229/pages/impersonate/[id].vue?macro=true";
import { default as indexbDITrvym9XMeta } from "/tmp/build_2e304229/pages/index.vue?macro=true";
import { default as _91suffix_93dH6649cDc3Meta } from "/tmp/build_2e304229/pages/invitation/[suffix].vue?macro=true";
import { default as _91token_93txzvJzDqOkMeta } from "/tmp/build_2e304229/pages/invite/[token].vue?macro=true";
import { default as _91id_93T5Ci9T0TUNMeta } from "/tmp/build_2e304229/pages/journeys/[id].vue?macro=true";
import { default as indexvHcdmao5RvMeta } from "/tmp/build_2e304229/pages/journeys/index.vue?macro=true";
import { default as membershNTHNNOzUZMeta } from "/tmp/build_2e304229/pages/myteam/members.vue?macro=true";
import { default as policy5ubBNlaKzZMeta } from "/tmp/build_2e304229/pages/myteam/policy.vue?macro=true";
import { default as validatorskQIRGWgd1cMeta } from "/tmp/build_2e304229/pages/myteam/validators.vue?macro=true";
import { default as myteamjLMowbNaC1Meta } from "/tmp/build_2e304229/pages/myteam.vue?macro=true";
import { default as new_45userwBKNOwuX5nMeta } from "/tmp/build_2e304229/pages/new-user.vue?macro=true";
import { default as passwordtSGM87FpVBMeta } from "/tmp/build_2e304229/pages/password.vue?macro=true";
import { default as indexmUmvnjVUZwMeta } from "/tmp/build_2e304229/pages/search/car/[search_id]/index.vue?macro=true";
import { default as indexRPl8Cfv4C0Meta } from "/tmp/build_2e304229/pages/search/car/index.vue?macro=true";
import { default as _91search_id_93yejAcQiOCZMeta } from "/tmp/build_2e304229/pages/search/car/init/[search_id].vue?macro=true";
import { default as _91search_id_93mWDZMfVmEWMeta } from "/tmp/build_2e304229/pages/search/flight/[search_id].vue?macro=true";
import { default as indexr48PNInBGoMeta } from "/tmp/build_2e304229/pages/search/flight/index.vue?macro=true";
import { default as index8tlIvDzOtrMeta } from "/tmp/build_2e304229/pages/search/hotel/[search_id]/index.vue?macro=true";
import { default as _91hotelIds_93izmYTHjHAjMeta } from "/tmp/build_2e304229/pages/search/hotel/[search_id]/rooms/[hotelIds].vue?macro=true";
import { default as indexUNqwydD11HMeta } from "/tmp/build_2e304229/pages/search/hotel/index.vue?macro=true";
import { default as _91search_id_93wglUSd44u6Meta } from "/tmp/build_2e304229/pages/search/train/[search_id].vue?macro=true";
import { default as index6m9p43Z9c1Meta } from "/tmp/build_2e304229/pages/search/train/index.vue?macro=true";
import { default as searchrAPD8yyrleMeta } from "/tmp/build_2e304229/pages/search.vue?macro=true";
import { default as indexJZpQZtxkOoMeta } from "/tmp/build_2e304229/pages/teams/[teamId]/index.vue?macro=true";
import { default as invitationfsG0zIcI4KMeta } from "/tmp/build_2e304229/pages/teams/[teamId]/invitation.vue?macro=true";
import { default as policyjyJvbcO2bxMeta } from "/tmp/build_2e304229/pages/teams/[teamId]/policy.vue?macro=true";
import { default as validatorslxZ6xIR6qDMeta } from "/tmp/build_2e304229/pages/teams/[teamId]/validators.vue?macro=true";
import { default as _91teamId_93CPPOVjllEDMeta } from "/tmp/build_2e304229/pages/teams/[teamId].vue?macro=true";
import { default as indexguNr6WgBLXMeta } from "/tmp/build_2e304229/pages/teams/index.vue?macro=true";
import { default as teams1MJjNE0vDqMeta } from "/tmp/build_2e304229/pages/teams.vue?macro=true";
import { default as index0aHz6ssDCPMeta } from "/tmp/build_2e304229/pages/train/[id]/cancel/index.vue?macro=true";
import { default as indexWvO5h44OUhMeta } from "/tmp/build_2e304229/pages/train/[id]/exchange/changeable-selection/index.vue?macro=true";
import { default as indexxaxGGVEZo9Meta } from "/tmp/build_2e304229/pages/train/[id]/exchange/section-selection/index.vue?macro=true";
import { default as indexsoTa4SeEKHMeta } from "/tmp/build_2e304229/pages/train/[id]/exchange/train-selection/index.vue?macro=true";
import { default as indexRkwfHCy5HvMeta } from "/tmp/build_2e304229/pages/train/[id]/index.vue?macro=true";
import { default as driver_45license85SxHPgZUcMeta } from "/tmp/build_2e304229/pages/users/[id]/driver-license.vue?macro=true";
import { default as fidelitygRpK7MefB6Meta } from "/tmp/build_2e304229/pages/users/[id]/fidelity.vue?macro=true";
import { default as indexnyk2KwXBB4Meta } from "/tmp/build_2e304229/pages/users/[id]/index.vue?macro=true";
import { default as passportKqEov3PE19Meta } from "/tmp/build_2e304229/pages/users/[id]/passport.vue?macro=true";
import { default as passwordifKh4pmEPNMeta } from "/tmp/build_2e304229/pages/users/[id]/password.vue?macro=true";
import { default as _91id_93G0TCipfmqmMeta } from "/tmp/build_2e304229/pages/users/[id].vue?macro=true";
import { default as indexmeScvmSZgLMeta } from "/tmp/build_2e304229/pages/users/index.vue?macro=true";
import { default as vouchersNt8oeTByNqMeta } from "/tmp/build_2e304229/pages/vouchers.vue?macro=true";
import { default as component_45stubrMUW8f3g9JMeta } from "/tmp/build_2e304229/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubrMUW8f3g9J } from "/tmp/build_2e304229/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "administration",
    path: "/administration",
    meta: administrationqT1ysyV6TCMeta || {},
    component: () => import("/tmp/build_2e304229/pages/administration.vue").then(m => m.default || m),
    children: [
  {
    name: "administration-admins",
    path: "admins",
    component: () => import("/tmp/build_2e304229/pages/administration/admins.vue").then(m => m.default || m)
  },
  {
    name: "administration-billing-id",
    path: "billing/:id()",
    component: () => import("/tmp/build_2e304229/pages/administration/billing/[id].vue").then(m => m.default || m)
  },
  {
    name: "administration-billing",
    path: "billing",
    component: () => import("/tmp/build_2e304229/pages/administration/billing/index.vue").then(m => m.default || m)
  },
  {
    name: "administration-bookings",
    path: "bookings",
    component: () => import("/tmp/build_2e304229/pages/administration/bookings.vue").then(m => m.default || m)
  },
  {
    name: "administration-ecostatistics",
    path: "ecostatistics",
    component: () => import("/tmp/build_2e304229/pages/administration/ecostatistics.vue").then(m => m.default || m)
  },
  {
    name: "administration-exports",
    path: "exports",
    component: () => import("/tmp/build_2e304229/pages/administration/exports.vue").then(m => m.default || m)
  },
  {
    name: "administration-fidelity",
    path: "fidelity",
    component: () => import("/tmp/build_2e304229/pages/administration/fidelity.vue").then(m => m.default || m)
  },
  {
    name: "administration-invoices",
    path: "invoices",
    component: () => import("/tmp/build_2e304229/pages/administration/invoices.vue").then(m => m.default || m)
  },
  {
    name: "administration-organization-policy",
    path: "organization/policy",
    component: () => import("/tmp/build_2e304229/pages/administration/organization/policy.vue").then(m => m.default || m)
  },
  {
    name: "administration-payment-id",
    path: "payment/:id()",
    component: () => import("/tmp/build_2e304229/pages/administration/payment/[id].vue").then(m => m.default || m)
  },
  {
    name: "administration-payment",
    path: "payment",
    component: () => import("/tmp/build_2e304229/pages/administration/payment/index.vue").then(m => m.default || m)
  },
  {
    name: "administration-statistics",
    path: "statistics",
    component: () => import("/tmp/build_2e304229/pages/administration/statistics.vue").then(m => m.default || m)
  },
  {
    name: _91teamId_93IBXjIGTKTOMeta?.name,
    path: "teams/:teamId()",
    component: () => import("/tmp/build_2e304229/pages/administration/teams/[teamId].vue").then(m => m.default || m),
    children: [
  {
    name: "administration-teams-teamId",
    path: "",
    meta: indexPYLNlgQxBFMeta || {},
    component: () => import("/tmp/build_2e304229/pages/administration/teams/[teamId]/index.vue").then(m => m.default || m)
  },
  {
    name: "administration-teams-teamId-invitation",
    path: "invitation",
    component: () => import("/tmp/build_2e304229/pages/administration/teams/[teamId]/invitation.vue").then(m => m.default || m)
  },
  {
    name: "administration-teams-teamId-policy",
    path: "policy",
    component: () => import("/tmp/build_2e304229/pages/administration/teams/[teamId]/policy/index.vue").then(m => m.default || m)
  },
  {
    name: "administration-teams-teamId-policy-modify",
    path: "policy/modify",
    meta: modifygOLOC4gtXuMeta || {},
    component: () => import("/tmp/build_2e304229/pages/administration/teams/[teamId]/policy/modify.vue").then(m => m.default || m)
  },
  {
    name: "administration-teams-teamId-validators",
    path: "validators",
    component: () => import("/tmp/build_2e304229/pages/administration/teams/[teamId]/validators.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "administration-teams",
    path: "teams",
    component: () => import("/tmp/build_2e304229/pages/administration/teams/index.vue").then(m => m.default || m)
  },
  {
    name: "administration-trip-labels",
    path: "trip-labels",
    component: () => import("/tmp/build_2e304229/pages/administration/trip-labels.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "auth-swile",
    path: "/auth/swile",
    meta: swileZTQlaElAoGMeta || {},
    component: () => import("/tmp/build_2e304229/pages/auth/swile.vue").then(m => m.default || m)
  },
  {
    name: "bookings",
    path: "/bookings",
    component: () => import("/tmp/build_2e304229/pages/bookings/index.vue").then(m => m.default || m)
  },
  {
    name: "bookings-validator",
    path: "/bookings/validator",
    meta: indexgWKSEmuCKDMeta || {},
    component: () => import("/tmp/build_2e304229/pages/bookings/validator/index.vue").then(m => m.default || m)
  },
  {
    name: "bookings-validator-pending",
    path: "/bookings/validator/pending",
    meta: pendingogTAoNKNhoMeta || {},
    component: () => import("/tmp/build_2e304229/pages/bookings/validator/pending.vue").then(m => m.default || m)
  },
  {
    name: "car-id",
    path: "/car/:id()",
    component: () => import("/tmp/build_2e304229/pages/car/[id].vue").then(m => m.default || m)
  },
  {
    name: "fidelity",
    path: "/fidelity",
    component: () => import("/tmp/build_2e304229/pages/fidelity.vue").then(m => m.default || m)
  },
  {
    name: "flight-id-exchange",
    path: "/flight/:id()/exchange",
    component: () => import("/tmp/build_2e304229/pages/flight/[id]/exchange.vue").then(m => m.default || m)
  },
  {
    name: "flight-id-fares",
    path: "/flight/:id()/fares",
    component: () => import("/tmp/build_2e304229/pages/flight/[id]/fares.vue").then(m => m.default || m)
  },
  {
    name: "flight-id",
    path: "/flight/:id()",
    component: () => import("/tmp/build_2e304229/pages/flight/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "forgot",
    path: "/forgot",
    meta: forgotHekMBPrEyEMeta || {},
    component: () => import("/tmp/build_2e304229/pages/forgot.vue").then(m => m.default || m)
  },
  {
    name: "get-swile-app",
    path: "/get-swile-app",
    meta: get_45swile_45appGJcc85pAK0Meta || {},
    component: () => import("/tmp/build_2e304229/pages/get-swile-app.vue").then(m => m.default || m)
  },
  {
    name: "help",
    path: "/help",
    component: () => import("/tmp/build_2e304229/pages/help.vue").then(m => m.default || m)
  },
  {
    name: "hotel-id",
    path: "/hotel/:id()",
    component: () => import("/tmp/build_2e304229/pages/hotel/[id].vue").then(m => m.default || m)
  },
  {
    name: "impersonate-id",
    path: "/impersonate/:id()",
    component: () => import("/tmp/build_2e304229/pages/impersonate/[id].vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    meta: indexbDITrvym9XMeta || {},
    component: () => import("/tmp/build_2e304229/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "invitation-suffix",
    path: "/invitation/:suffix()",
    meta: _91suffix_93dH6649cDc3Meta || {},
    component: () => import("/tmp/build_2e304229/pages/invitation/[suffix].vue").then(m => m.default || m)
  },
  {
    name: "invite-token",
    path: "/invite/:token()",
    meta: _91token_93txzvJzDqOkMeta || {},
    component: () => import("/tmp/build_2e304229/pages/invite/[token].vue").then(m => m.default || m)
  },
  {
    name: "journeys-id",
    path: "/journeys/:id()",
    component: () => import("/tmp/build_2e304229/pages/journeys/[id].vue").then(m => m.default || m)
  },
  {
    name: "journeys",
    path: "/journeys",
    component: () => import("/tmp/build_2e304229/pages/journeys/index.vue").then(m => m.default || m)
  },
  {
    name: "myteam",
    path: "/myteam",
    component: () => import("/tmp/build_2e304229/pages/myteam.vue").then(m => m.default || m),
    children: [
  {
    name: "myteam-members",
    path: "members",
    component: () => import("/tmp/build_2e304229/pages/myteam/members.vue").then(m => m.default || m)
  },
  {
    name: "myteam-policy",
    path: "policy",
    component: () => import("/tmp/build_2e304229/pages/myteam/policy.vue").then(m => m.default || m)
  },
  {
    name: "myteam-validators",
    path: "validators",
    component: () => import("/tmp/build_2e304229/pages/myteam/validators.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "new-user",
    path: "/new-user",
    meta: new_45userwBKNOwuX5nMeta || {},
    component: () => import("/tmp/build_2e304229/pages/new-user.vue").then(m => m.default || m)
  },
  {
    name: "password",
    path: "/password",
    meta: passwordtSGM87FpVBMeta || {},
    component: () => import("/tmp/build_2e304229/pages/password.vue").then(m => m.default || m)
  },
  {
    name: "search",
    path: "/search",
    component: () => import("/tmp/build_2e304229/pages/search.vue").then(m => m.default || m),
    children: [
  {
    name: "search-car-search_id",
    path: "car/:search_id()",
    component: () => import("/tmp/build_2e304229/pages/search/car/[search_id]/index.vue").then(m => m.default || m)
  },
  {
    name: "search-car",
    path: "car",
    component: () => import("/tmp/build_2e304229/pages/search/car/index.vue").then(m => m.default || m)
  },
  {
    name: "search-car-init-search_id",
    path: "car/init/:search_id()",
    component: () => import("/tmp/build_2e304229/pages/search/car/init/[search_id].vue").then(m => m.default || m)
  },
  {
    name: "search-flight-search_id",
    path: "flight/:search_id()",
    component: () => import("/tmp/build_2e304229/pages/search/flight/[search_id].vue").then(m => m.default || m)
  },
  {
    name: "search-flight",
    path: "flight",
    component: () => import("/tmp/build_2e304229/pages/search/flight/index.vue").then(m => m.default || m)
  },
  {
    name: "search-hotel-search_id",
    path: "hotel/:search_id()",
    component: () => import("/tmp/build_2e304229/pages/search/hotel/[search_id]/index.vue").then(m => m.default || m)
  },
  {
    name: "search-hotel-search_id-rooms-hotelIds",
    path: "hotel/:search_id()/rooms/:hotelIds()",
    component: () => import("/tmp/build_2e304229/pages/search/hotel/[search_id]/rooms/[hotelIds].vue").then(m => m.default || m)
  },
  {
    name: "search-hotel",
    path: "hotel",
    component: () => import("/tmp/build_2e304229/pages/search/hotel/index.vue").then(m => m.default || m)
  },
  {
    name: "search-train-search_id",
    path: "train/:search_id()",
    component: () => import("/tmp/build_2e304229/pages/search/train/[search_id].vue").then(m => m.default || m)
  },
  {
    name: "search-train",
    path: "train",
    component: () => import("/tmp/build_2e304229/pages/search/train/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: teams1MJjNE0vDqMeta?.name,
    path: "/teams",
    meta: teams1MJjNE0vDqMeta || {},
    component: () => import("/tmp/build_2e304229/pages/teams.vue").then(m => m.default || m),
    children: [
  {
    name: _91teamId_93CPPOVjllEDMeta?.name,
    path: ":teamId()",
    component: () => import("/tmp/build_2e304229/pages/teams/[teamId].vue").then(m => m.default || m),
    children: [
  {
    name: "teams-teamId",
    path: "",
    component: () => import("/tmp/build_2e304229/pages/teams/[teamId]/index.vue").then(m => m.default || m)
  },
  {
    name: "teams-teamId-invitation",
    path: "invitation",
    component: () => import("/tmp/build_2e304229/pages/teams/[teamId]/invitation.vue").then(m => m.default || m)
  },
  {
    name: "teams-teamId-policy",
    path: "policy",
    component: () => import("/tmp/build_2e304229/pages/teams/[teamId]/policy.vue").then(m => m.default || m)
  },
  {
    name: "teams-teamId-validators",
    path: "validators",
    component: () => import("/tmp/build_2e304229/pages/teams/[teamId]/validators.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "teams",
    path: "",
    component: () => import("/tmp/build_2e304229/pages/teams/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "train-id-cancel",
    path: "/train/:id()/cancel",
    component: () => import("/tmp/build_2e304229/pages/train/[id]/cancel/index.vue").then(m => m.default || m)
  },
  {
    name: "train-id-exchange-changeable-selection",
    path: "/train/:id()/exchange/changeable-selection",
    component: () => import("/tmp/build_2e304229/pages/train/[id]/exchange/changeable-selection/index.vue").then(m => m.default || m)
  },
  {
    name: "train-id-exchange-section-selection",
    path: "/train/:id()/exchange/section-selection",
    component: () => import("/tmp/build_2e304229/pages/train/[id]/exchange/section-selection/index.vue").then(m => m.default || m)
  },
  {
    name: "train-id-exchange-train-selection",
    path: "/train/:id()/exchange/train-selection",
    component: () => import("/tmp/build_2e304229/pages/train/[id]/exchange/train-selection/index.vue").then(m => m.default || m)
  },
  {
    name: "train-id",
    path: "/train/:id()",
    component: () => import("/tmp/build_2e304229/pages/train/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93G0TCipfmqmMeta?.name,
    path: "/users/:id()",
    component: () => import("/tmp/build_2e304229/pages/users/[id].vue").then(m => m.default || m),
    children: [
  {
    name: "users-id-driver-license",
    path: "driver-license",
    component: () => import("/tmp/build_2e304229/pages/users/[id]/driver-license.vue").then(m => m.default || m)
  },
  {
    name: "users-id-fidelity",
    path: "fidelity",
    component: () => import("/tmp/build_2e304229/pages/users/[id]/fidelity.vue").then(m => m.default || m)
  },
  {
    name: "users-id",
    path: "",
    component: () => import("/tmp/build_2e304229/pages/users/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "users-id-passport",
    path: "passport",
    component: () => import("/tmp/build_2e304229/pages/users/[id]/passport.vue").then(m => m.default || m)
  },
  {
    name: "users-id-password",
    path: "password",
    component: () => import("/tmp/build_2e304229/pages/users/[id]/password.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "users",
    path: "/users",
    component: () => import("/tmp/build_2e304229/pages/users/index.vue").then(m => m.default || m)
  },
  {
    name: "vouchers",
    path: "/vouchers",
    component: () => import("/tmp/build_2e304229/pages/vouchers.vue").then(m => m.default || m)
  },
  {
    name: "teams-vouchers",
    path: "/teams-vouchers",
    component: () => import("/tmp/build_2e304229/pages/vouchers.vue").then(m => m.default || m)
  },
  {
    name: component_45stubrMUW8f3g9JMeta?.name,
    path: "/metrics",
    component: component_45stubrMUW8f3g9J
  },
  {
    name: component_45stubrMUW8f3g9JMeta?.name,
    path: "/en",
    component: component_45stubrMUW8f3g9J
  },
  {
    name: component_45stubrMUW8f3g9JMeta?.name,
    path: "/fr",
    component: component_45stubrMUW8f3g9J
  },
  {
    name: component_45stubrMUW8f3g9JMeta?.name,
    path: "/pt-BR",
    component: component_45stubrMUW8f3g9J
  },
  {
    name: component_45stubrMUW8f3g9JMeta?.name,
    path: "/en/:pathMatch(.*)",
    component: component_45stubrMUW8f3g9J
  },
  {
    name: component_45stubrMUW8f3g9JMeta?.name,
    path: "/fr/:pathMatch(.*)",
    component: component_45stubrMUW8f3g9J
  },
  {
    name: component_45stubrMUW8f3g9JMeta?.name,
    path: "/pt-BR/:pathMatch(.*)",
    component: component_45stubrMUW8f3g9J
  }
]